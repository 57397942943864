import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { ApiService } from '../core/api.service';
import { SharedService } from '../shared/shared.service';
import { ITemplate } from '../shared/shared.definitions';

@Injectable()
export class TemplateBuilderResolver implements Resolve<any> {

  constructor(
    private apiService: ApiService,
    private sharedService: SharedService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> {
    const id = route.params.id;

    return Observable.create((observer: Observer<any>) => {
      if (id) {
        this.apiService.getTemplate(id).subscribe(
          (result: any) => {
            this.sharedService.updateTemplate(result.data);
            observer.next(result.data);
            observer.complete();
          },
          (error) => {
            const template = (<ITemplate[]>JSON.parse(localStorage.getItem('templates'))).find(t => t.id === id);
            if (template) {
              this.sharedService.updateTemplate(template);
              observer.next(template);
            } else {
              observer.error(error);
            }
            observer.complete();
          });
      } else {
        // this.sharedService.initTemplate();
        observer.next(false);
        observer.complete();
      }
    });
  }
}
