import { NgModule } from '@angular/core';
import { ReqService } from './req.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './api.service';
import { CoreHttpInterceptor } from './http.interceptor';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    ReqService,
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreHttpInterceptor,
      multi: true
    },
  ],
  exports: [
    HttpClientModule
  ]
})
export class CoreModule { }
