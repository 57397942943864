import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
  ViewRef
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
// @ts-ignore
import * as deepcopy from 'deepcopy';
import { finalize } from 'rxjs/operators';

import { ISidebarType, ITemplate, ITemplateSection, SideBarType } from '../shared/shared.definitions';
import { SharedService } from '../shared/shared.service';
import { SectionComponent } from './components/section/section.component';

declare var $: any;

@Component({
  selector: 'app-template-builder',
  templateUrl: './template-builder.component.html',
  styleUrls: ['./template-builder.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TemplateBuilderComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {

  @ViewChild('htmlModal') private htmlModal: SwalComponent;
  @ViewChild('deleteRowModal') private deleteRowModal: SwalComponent;
  @ViewChild('sidenav') private sidenav: MatSidenav;

  @ViewChildren('sectionAnchor', { read: ViewContainerRef }) private sectionAnchors: QueryList<ViewContainerRef>;

  SideBarType = SideBarType;
  sideBarType: ISidebarType;
  template: ITemplate;
  selectedSection: ITemplateSection;
  html: string;
  rowIndexToDelete: number;
  isPreviewOpen: boolean;
  templateNameRequiredError: boolean;
  isEditOnly: boolean;
  isModified: boolean;
  isSaving: boolean;

  constructor(
    private sharedService: SharedService,
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isEditOnly = this.route.snapshot.queryParams.editonly;
    this.templateNameRequiredError = false;
    this.sharedService.sideBarType.subscribe(res => {
      this.sideBarType = res;
      this.highlightContainer();
    });
    this.sharedService.template.subscribe(res => {
      if (this.template) {
        this.isModified = true;
      }
      this.template = res;
      setTimeout(() => this.generateSections(), 0);
    });
    this.sharedService.selectedSection.subscribe(res => {
      this.selectedSection = res;
    });
  }

  ngAfterViewInit() {
    this.generateSections();
    this.sidenav.closedStart.subscribe(() => this.sharedService.setSideBarType(SideBarType.NONE));
  }

  ngAfterViewChecked() {
    Array.from($('.section-layout-table')).forEach((element: HTMLElement) => {
      if (!element.classList.contains('ui-resizable')) {
        $(element).resizable({
          handles: 's',
          stop: (event, ui) => this.setRowHeight(element, ui)
         });
      }
    });
  }

  ngOnDestroy() {
    this.cd.detach();
  }

  onDrop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // const sectionsCount = this.template.rows ? this.template.rows.reduce((acc, row) => acc + row.sections.length, 0) : 0;
      // const rowCount = this.template.rows.length;
      const prevContainerCopy = JSON.parse(JSON.stringify(event.previousContainer.data));
      transferArrayItem(prevContainerCopy, event.container.data, event.previousIndex, event.currentIndex);
      const nextRowId = this.getNextRowId();
      this.template.rows[event.currentIndex].id = nextRowId;
      this.template.rows[event.currentIndex].sections.forEach((section, index) => {
        const id = parseInt('' + nextRowId + index, 10);
        section.id = id;
      });
    }
    this.sharedService.updateTemplate(this.template);
  }

  private getNextRowId() {
    const rowIds = this.template.rows.map(row => row.id);
    return this.template.rows.length ? Math.max(...rowIds) + 1 : 0;
  }

  onSectionSelect(section: ITemplateSection) {
    this.highlightContainer(section);
    this.sharedService.selectSection(section);
    if (section.icon) {
      this.sharedService.openEditor(section.icon.type);
    } else {
      this.sharedService.setSideBarType(SideBarType.SECTION_LIST);
    }
    this.sidenav.open();
  }

  onDeleteRowConfirm(rowIndex: number) {
    this.rowIndexToDelete = rowIndex;
    this.deleteRowModal.show().then(() => this.rowIndexToDelete = null);
  }

  onDeleteRow() {
    this.template.rows.splice(this.rowIndexToDelete, 1);
    this.sharedService.updateTemplate(this.template);
    this.sharedService.setSideBarType(SideBarType.LAYOUT_LIST, 'side');
  }

  onDuplicateRowButtonClick(rowIndex: number) {
    this.isModified = true;
    const newRow = deepcopy(this.template.rows[rowIndex]);
    const newRowId = this.getNextRowId();
    newRow.id = newRowId;
    newRow.sections.forEach((section: ITemplateSection, index) => {
      section.id = parseInt('' + newRowId + index, 10);
    });
    this.template.rows.push(newRow);
    this.sharedService.updateTemplate(this.template);
    this.sharedService.setSideBarType(SideBarType.LAYOUT_LIST, 'side');
  }

  generateSections() {
    if (this.sectionAnchors) {
      this.sectionAnchors.forEach(vcr => vcr.clear());
    }

    if (this.template.rows) {
      this.template.rows.forEach(row => {
        row.sections.forEach(section => {
          if (this.sectionAnchors && section.icon) {
            const sectionId = `section-${section.id}`;
            const container = document.getElementById(sectionId);
            this.setSectionContainerStyle(section, container);
            const viewContainerRef = this.sectionAnchors.find(vcr => vcr.element.nativeElement.parentElement.id === sectionId);
            if (viewContainerRef) {
              const componentRef = this.sharedService.createElementVC(SectionComponent, viewContainerRef);
              componentRef.instance.section = section;
            }
          }
        });
      });
    }
    if (!(this.cd as ViewRef).destroyed) {
      this.cd.detectChanges();
    }
  }

  onBack() {
    this.sharedService.setSideBarType(SideBarType.LAYOUT_LIST, 'side');
  }

  onGenerateHtml() {
    this.html = this.sharedService.generateHtml();
    this.htmlModal.show();
  }

  onSaveTemplate() {
    this.saveTemplate();
  }

  onSaveTemplateAsNew() {
    this.saveTemplate(this.sharedService.getNewGeneratedTemplateID());
  }

  private saveTemplate(saveAsID?: string) {
    if (!this.template.details || !this.template.details.name) {
      this.templateNameRequiredError = true;
      return;
    }

    this.templateNameRequiredError = false;
    this.template.html = this.sharedService.generateHtml();
    this.template.id = saveAsID || this.template.id;
    this.isSaving = true;
    this.sharedService.saveTemplate(this.template).pipe(
      finalize(() => {
        this.isModified = false;
        this.isSaving = false;
      })
    ).subscribe(res => {
      if (res) {
        this.sharedService.deleteTemplateLocalById('template.id');
        this.template.id = res.data.id;
        this.template.isOnline = true;
        this.sharedService.updateTemplate(this.template);
        this.snackBar.open('Sablon sikeresen elmentve!', '', {duration: 5000, panelClass: 'success', verticalPosition: 'top'});
      } else {
        this.snackBar.open('Szerver hiba! Sablon csak lokálisan mentve!', '',
          {duration: 5000, panelClass: 'warning', verticalPosition: 'top'});
      }
    });
  }

  onSaveChanges() {
    if (<any>parent && (typeof (<any>parent).postMessage !== 'undefined')) {
      (<any>parent).postMessage({
        html: this.template.html,
        name: this.template.details && this.template.details.name
      }, '*');
    }
  }

  onTemplateLayouts() {
    if (this.sidenav.opened && this.sideBarType.sidebar === SideBarType.LAYOUT_LIST) {
      this.sidenav.close();
    } else {
      this.sharedService.setSideBarType(SideBarType.LAYOUT_LIST, 'side');
      this.sidenav.open();
    }
  }

  onTemplateSettings() {
    if (this.sidenav.opened && this.sideBarType.sidebar === SideBarType.TEMPLATE_SETTINGS) {
      this.sidenav.close();
    } else {
      this.sharedService.setSideBarType(SideBarType.TEMPLATE_SETTINGS, 'side');
      this.sidenav.open();
    }
  }

  onCopyHtml() {
    const textarea: HTMLTextAreaElement = <HTMLTextAreaElement>document.getElementById('html-textarea');
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = textarea.value;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    this.snackBar.open('HTML sikeresen a vágólapra másolva!', '', { duration: 3000, panelClass: 'success', verticalPosition: 'top' });
  }

  onPreview() {
    // tslint:disable-next-line:max-line-length
    const previewWindow = window.open('', '_blank', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=900,top=0,left=0');
    this.isPreviewOpen = true;
    previewWindow.document.write(this.html);
    previewWindow.onbeforeunload = () => {
      this.isPreviewOpen = false;
      this.cd.detectChanges();
    };
  }

  onTemplateNameChange(event) {
    const name = event.target.value;
    if (name) {
      this.templateNameRequiredError = false;
    } else {
      this.templateNameRequiredError = true;
    }
    this.sharedService.setTemplateDetails({ name });
  }

  getOuterSectionWidth(section: ITemplateSection): number {
    return this.sharedService.getOuterSectionWidth(section);
  }

  getInnerSectionWidth(section: ITemplateSection): number {
    return this.sharedService.getInnerSectionWidth(section);
  }

  parseInt(value: string) {
    return parseInt(value, 10);
  }

  private setSectionContainerStyle(section: ITemplateSection, container: HTMLElement) {
    if (section.content && section.content.options.verticalAlign) {
      container.style.verticalAlign = section.content.options.verticalAlign;
    }
    if (section.content && section.content.options.horizontalAlign) {
      container.style.textAlign = section.content.options.horizontalAlign;
    }
    container.style.padding = section.content &&
      `${section.content.options.paddingTop}
       ${section.content.options.paddingRight}
       ${section.content.options.paddingBottom}
       ${section.content.options.paddingLeft}`;
  }

  private highlightContainer(section?: ITemplateSection) {
    if (section) {
      const container = document.getElementById(`section-${section.id}`);
      container.classList.add('selected');
      if (this.selectedSection && this.selectedSection.id !== section.id) {
        const prevContianer = document.getElementById(`section-${this.selectedSection.id}`);
        if (prevContianer) {
          prevContianer.classList.remove('selected');
        }
      }
    } else if (this.sideBarType.sidebar === SideBarType.LAYOUT_LIST && this.selectedSection) {
      const prevContianer = document.getElementById(`section-${this.selectedSection.id}`);
      if (prevContianer) {
        prevContianer.classList.remove('selected');
      }
    }
  }

  private setRowHeight(element: HTMLElement, ui) {
    console.log(element.id);
    const id = +element.id.split('-')[1];
    const row = this.sharedService.findRowById(id);
    row.height = `${ui.size.height}px`;
    this.sharedService.editRow(row);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.isModified) {
      $event.returnValue = 'Nem mentett változásaid vannak! Biztos el akarod hagyni az oldalt?';
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.sidenav.close();
  }

  @HostListener('window:scroll', ['$event'])
  onScrollHandler(event) {
    const btnContainer = <HTMLElement>document.getElementsByClassName('sidenav-button-container')[0];
    btnContainer.style.top = `${window.scrollY > 200 ? window.scrollY - 200 : 0}px`;
  }
}
