import { Component, OnInit, Input, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { ITemplateSection, ITemplateSectionImage } from 'src/app/shared/shared.definitions';
import { SharedService } from 'src/app/shared/shared.service';

declare var $: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[app-section]',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SectionComponent implements OnInit, AfterViewInit {

  @Input() section: ITemplateSection;

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    console.log('this.section: ', this.section);
    $('a').click((e) => e.preventDefault());
  }

  getImageWidth(section: ITemplateSection) {
    const content = <ITemplateSectionImage>section.content;
    const fullWidth = content.width.indexOf('%') === -1 && content.width.indexOf('auto') === -1
      ? parseInt(content.width, 10)
      : 580 / (100 / section.layout.size);
    const sectionMargin = parseInt(content.options.marginLeft, 10) + parseInt(content.options.marginRight, 10);
    return fullWidth - sectionMargin - 10;
  }

  getInnerSectionWidth(section: ITemplateSection): number {
    return this.sharedService.getInnerSectionWidth(section, true);
  }

  removePx(size: string) {
    return size.replace('px', '');
  }

}
