import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-template-builder-actions-row',
  templateUrl: './template-builder-actions-row.component.html',
})
export class TemplateBuilderActionsRowComponent {

  @Input() isEditOnly = false;
  @Input() templateNameRequiredError;
  @Input() isModified = false;
  @Input() isPreviewOpen = false;
  @Input() templateName = '';

  @Output() templateNameChange = new EventEmitter();
  @Output() saveTemplate = new EventEmitter();
  @Output() saveTemplateAsNew = new EventEmitter();
  @Output() saveChanges = new EventEmitter();
  @Output() generateHtml = new EventEmitter();

  onKeyUp(event) {
    this.templateNameChange.emit(event);
  }

  onSaveTemplateButtonClick() {
    this.saveTemplate.emit();
  }

  onSaveTemplateAsNewButtonClick() {
    this.saveTemplateAsNew.emit();
  }

  onSaveChangesButtonClick() {
    this.saveChanges.emit();
  }

  onGenerateHtml() {
    this.generateHtml.emit();
  }

}
