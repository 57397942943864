import { Injectable } from '@angular/core';
import { ReqService } from './req.service';
import { ITemplate } from '../shared/shared.definitions';
import { Observable } from 'rxjs';

@Injectable()
export class ApiService {

  constructor(
    private req: ReqService
  ) { }

  getTemplates(): Observable<ITemplate[]> {
    return this.req.get('templates');
  }

  getTemplate(id: string): Observable<ITemplate> {
    return this.req.get(`templates/${id}`);
  }

  createTemplate(template: ITemplate) {
    return this.req.post('templates', template);
  }

  updateTemplate(template: ITemplate) {
    return this.req.put(`templates/${template.id}`, template);
  }

  deleteTemplate(template: ITemplate) {
    return this.req.delete(`templates/${template.id}`);
  }

  uploadImage(name: string, file: File) {
    const form = new FormData();
    form.append(name, file);
    return this.req.post('upload-image', form);
  }
}
