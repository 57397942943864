import { Routes } from '@angular/router';
import { TemplateSelectorComponent } from './template-selector/template-selector.component';
import { TemplateSelectorResolver } from './template-selector/template-selector.resolver';
import { TemplateBuilderComponent } from './template-builder/template-builder.component';
import { TemplateBuilderResolver } from './template-builder/template-builder.resolver';
import { TemplateBuilderGuard } from './template-builder/template-builder.guard';

export const appRoutes: Routes = [
  {
    path: '',
    component: TemplateSelectorComponent,
    resolve: { data: TemplateSelectorResolver }
  },
  {
    path: 'template-builder/new',
    component: TemplateBuilderComponent,
    resolve: { data: TemplateBuilderResolver },
    canDeactivate: [TemplateBuilderGuard]
  },
  {
    path: 'template-builder/:id',
    component: TemplateBuilderComponent,
    resolve: { data: TemplateBuilderResolver },
    canDeactivate: [TemplateBuilderGuard]
  },
  {
    path: '**',
    redirectTo: ''
  }
];
