import { Component, OnInit, Input } from '@angular/core';
import { SectionType, ITemplateSection, SideBarType } from 'src/app/shared/shared.definitions';
import { SharedService } from 'src/app/shared/shared.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.scss']
})
export class SectionListComponent implements OnInit {

  @Input() sidenav: MatSidenav;

  sectionInfos: ITemplateSection[];
  selectedSection: ITemplateSection;

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.sharedService.selectedSection.subscribe(section => this.selectedSection = section);
    this.sectionInfos = this.getSectionInfos();
  }

  getSectionInfos(): ITemplateSection[] {
    return [
      {
      id: null,
      icon: {
        icon: 'art_track',
        name: 'Cikk',
        type: SectionType.ARTICLE,
      },
      layout: null,
      content: null,
     },
     {
      id: null,
      icon: {
        icon: 'view_compact',
        name: 'Iker cikk',
        type: SectionType.TWIN_ARTICLE,
      },
      layout: null,
      content: null,
     },
     {
      id: null,
      icon: {
        icon: 'insert_photo',
        name: 'Kép',
        type: SectionType.IMAGE,
      },
      layout: null,
      content: null,
     },
     {
      id: null,
      icon: {
        icon: 'title',
        name: 'Szöveg',
        type: SectionType.TEXT,
      },
      layout: null,
      content: null,
     },
     {
      id: null,
      icon: {
        icon: 'share',
        name: 'Közösségi',
        type: SectionType.SOCIAL,
      },
      layout: null,
      content: null,
     },
     {
      id: null,
      icon: {
        icon: 'power_input',
        name: 'Elválasztó',
        type: SectionType.SEPARATOR,
      },
      layout: null,
      content: null,
     },
     {
      id: null,
      icon: {
        icon: 'ondemand_video',
        name: 'Videó',
        type: SectionType.VIDEO,
      },
      layout: null,
      content: null,
     }
   ];
 }

 onSelectSection(sectionInfo: ITemplateSection) {
   this.selectedSection.icon = sectionInfo.icon;
   this.sharedService.editSection(this.selectedSection);
   this.sharedService.openEditor(sectionInfo.icon.type);
 }

 onBack() {
  this.sidenav.close();
 }

}
