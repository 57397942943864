declare var window: any;

export class DynamicEnvironment {

  public get apiUrl() {
    return window.config.api ? window.config.api : 'http://mki-newsletter-cms.dev.trendency.hu/cms/newsletter';
    // 'http://novekedes-api.dev.trendency.hu/publicapi/v1/novekedes/template-editor';
    // http://novekedes-cms.dev.trendency.hu/cms/novekedes/newsletter
  }

  public get baseUrl() {
    return window.config.baseUrl ? window.config.baseUrl : 'http://mki-newsletter-cms.dev.trendency.hu' ;
  }

  public get token() {
    return window.config.token ? window.config.token : '';
  }
}
