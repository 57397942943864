import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ITemplateSection, ITemplateSectionImage, SideBarType } from 'src/app/shared/shared.definitions';
import { SharedService } from 'src/app/shared/shared.service';
import { ApiService } from 'src/app/core/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSidenav } from '@angular/material/sidenav';

declare var Dropzone: any;

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnInit, AfterViewInit {

  @Input() sidenav: MatSidenav;

  selectedSection: ITemplateSection;
  content: ITemplateSectionImage;
  imageForm: FormGroup;
  dropzone: any;
  isModified: boolean;

  constructor(
    private sharedService: SharedService,
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    Dropzone.options.dropzone = {
      autoProcessQueue: false,
      maxFiles: 1,
      accept: (file, done) => {
        this.imageForm.controls.file.setValue(file);
        done();
      },
      acceptedFiles: 'image/*',
      maxfilesexceeded: function(file) {
        this.removeAllFiles();
        this.addFile(file);
      }
    };

    this.sharedService.selectedSection.subscribe(section => {
      this.selectedSection = section;
      this.content = <ITemplateSectionImage>(this.selectedSection.content || {});
      this.imageForm = new FormGroup({
        file: new FormControl(),
        src: new FormControl(this.content.src || ''),
        width: new FormControl(this.content.width || '100%'),
        height: new FormControl(this.content.height || 'auto'),
        link: new FormControl(this.content.link || ''),
        options: new FormGroup({
          verticalAlign: new FormControl(this.content.options && this.content.options.verticalAlign || 'middle'),
          horizontalAlign: new FormControl(this.content.options && this.content.options.horizontalAlign || 'center'),
          paddingTop: new FormControl(this.content.options && this.content.options.paddingTop || '5px'),
          paddingBottom: new FormControl(this.content.options && this.content.options.paddingBottom || '5px'),
          paddingLeft: new FormControl(this.content.options && this.content.options.paddingLeft || '5px'),
          paddingRight: new FormControl(this.content.options && this.content.options.paddingRight || '5px'),
          marginTop: new FormControl(this.content.options && this.content.options.marginTop || '5px'),
          marginBottom: new FormControl(this.content.options && this.content.options.marginBottom || '5px'),
          marginLeft: new FormControl(this.content.options && this.content.options.marginLeft || '5px'),
          marginRight: new FormControl(this.content.options && this.content.options.marginRight || '5px'),
          backgroundColor: new FormControl(this.content.options && this.content.options.backgroundColor || '#ffffff'),
          outlookSectionHeight: new FormControl(this.content.options && this.content.options.outlookSectionHeight || 0),
        })
      });

      this.imageForm.valueChanges.subscribe(() => this.isModified = true);
    });
  }

  ngAfterViewInit() {
    this.dropzone = new Dropzone('.dropzone');
  }

  onSave() {
    const { file } = this.imageForm.value;
    Object.keys(this.imageForm.value).forEach(key => {
      if (key !== 'file') {
        this.content[key] = this.imageForm.value[key];
      }
    });
    if (file) {
      this.apiService.uploadImage('file', file).subscribe(
      (res) => {
        try {
          this.content.src = res.data[0] && res.data[0].content;
          this.saveContent();
        } catch {
          this.snackBar.open('Kép mentése sikertelen!', '',
          { duration: 5000, panelClass: 'error', verticalPosition: 'top' });
        }
      },
      (err) => {
        // this.content.src = URL.createObjectURL(file);
        this.snackBar.open('Kép mentése sikertelen! A maximális képméret: 5MB', '',
          { duration: 5000, panelClass: 'error', verticalPosition: 'top' });
      });
    } else {
      this.saveContent();
    }
  }

  onDelete() {
    this.sharedService.deleteSection(this.selectedSection);
    this.sidenav.close();
  }

  onBack() {
    let confirmation = true;
    if (this.isModified) {
      confirmation = confirm('Nem mentett változásaid vannak! Biztos visszalépsz?');
    }
    if (confirmation) {
      this.isModified = false;
      this.sidenav.close();
    }
  }

  private saveContent() {
    this.selectedSection.content = this.content;
    this.isModified = false;
    this.sharedService.editSection(this.selectedSection);
    this.sidenav.close();
  }

}
