import { Component, OnInit, Input } from '@angular/core';
import { ITemplateSection, ITemplateSectionSocial, SideBarType } from 'src/app/shared/shared.definitions';
import { SharedService } from 'src/app/shared/shared.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-social-editor',
  templateUrl: './social-editor.component.html',
  styleUrls: ['./social-editor.component.scss']
})
export class SocialEditorComponent implements OnInit {

  @Input() sidenav: MatSidenav;

  selectedSection: ITemplateSection;
  content: ITemplateSectionSocial;
  socialForm: FormGroup;
  isModified: boolean;

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.sharedService.selectedSection.subscribe(section => {
      this.selectedSection = section;
      this.content = <ITemplateSectionSocial>(this.selectedSection.content || {});
      this.socialForm = new FormGroup({
        facebook: new FormControl(this.content.facebook || ''),
        twitter: new FormControl(this.content.twitter || ''),
        instagram: new FormControl(this.content.instagram || ''),
        youtube: new FormControl(this.content.youtube || ''),
        flickr: new FormControl(this.content.flickr || ''),
        issuu: new FormControl(this.content.issuu || ''),
        vimeo: new FormControl(this.content.vimeo || ''),
        size: new FormControl(this.content.size || '25px'),
        options: new FormGroup({
          verticalAlign: new FormControl(this.content.options && this.content.options.verticalAlign || 'middle'),
          horizontalAlign: new FormControl(this.content.options && this.content.options.horizontalAlign || 'center'),
          paddingTop: new FormControl(this.content.options && this.content.options.paddingTop || '5px'),
          paddingBottom: new FormControl(this.content.options && this.content.options.paddingBottom || '5px'),
          paddingLeft: new FormControl(this.content.options && this.content.options.paddingLeft || '5px'),
          paddingRight: new FormControl(this.content.options && this.content.options.paddingRight || '5px'),
          backgroundColor: new FormControl(this.content.options && this.content.options.backgroundColor || '#ffffff'),
        })
      });

      this.socialForm.valueChanges.subscribe(() => this.isModified = true);
    });
  }

  onSave() {
    Object.keys(this.socialForm.value).forEach(key => this.content[key] = this.socialForm.value[key]);
    this.selectedSection.content = this.content;
    this.sharedService.editSection(this.selectedSection);
    this.isModified = false;
    this.sidenav.close();
  }

  onDelete() {
    this.sharedService.deleteSection(this.selectedSection);
    this.sidenav.close();
  }

  onBack() {
    let confirmation = true;
    if (this.isModified) {
      confirmation = confirm('Nem mentett változásaid vannak! Biztos visszalépsz?');
    }
    if (confirmation) {
      this.isModified = false;
      this.sidenav.close();
    }
  }

}
