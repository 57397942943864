import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ITemplate } from '../shared/shared.definitions';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared/shared.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ApiService } from '../core/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.scss']
})
export class TemplateSelectorComponent implements OnInit {

  @ViewChild('deleteTemplateModal') private deleteTemplateModal: SwalComponent;
  @ViewChild('fileUpload') private fileUpload: ElementRef;

  templates: ITemplate[];
  templateToDelete: ITemplate;
  isPreviewOpen: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cd: ChangeDetectorRef,
    private sharedService: SharedService,
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    const templatesTemp = this.route.snapshot.data.data;
    this.templates = templatesTemp.online.concat(templatesTemp.offline);
  }

  onNewTemplate() {
    this.sharedService.initTemplate();
    this.router.navigate(['/', 'template-builder', 'new'], { queryParamsHandling: 'preserve' });
  }

  onPreview(template: ITemplate) {
    if (template.html) {
      // tslint:disable-next-line:max-line-length
      const previewWindow = window.open('', '_blank', 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=900,top="+(screen.height-400)+",left="+(screen.width-840)');
      this.isPreviewOpen = true;
      previewWindow.document.write(template.html);
      previewWindow.onbeforeunload = () => {
        this.isPreviewOpen = false;
        this.cd.detectChanges();
      };
    }
  }

  onOpen(template: ITemplate) {
    this.router.navigate(['/', 'template-builder', template.id], { queryParamsHandling: 'preserve' });
  }

  onLoadFromFile() {
    this.fileUpload.nativeElement.click();
  }

  onFileloaded(event) {
    const input = event.target;
    const reader = new FileReader();
    reader.onload = () => {
      const templateString = reader.result.toString();
      let error;
      let template;
      try {
        template = JSON.parse(decodeURIComponent(escape(atob(templateString))));
      } catch (e) {
        error = true;
      }
      if (!error) {
        this.sharedService.updateTemplate(template, true);
        this.router.navigate(['/', 'template-builder', 'new'], { queryParamsHandling: 'preserve' });
      } else {
        this.snackBar.open('Nem lehet betölteni a sablont! Hibás fájl!', '',
          { duration: 5000, panelClass: 'error', verticalPosition: 'top' });
      }
    };
    reader.readAsText(input.files[0]);
  }

  onDeleteConfirm(template: ITemplate) {
    this.templateToDelete = template;
    this.deleteTemplateModal.show().then(() => this.templateToDelete = null);
  }

  onDelete() {
    if (this.templateToDelete) {
      this.sharedService.deleteTemplate(this.templateToDelete).subscribe(templates => {
        console.log(templates);
        this.templateToDelete = null;
        const localTemplates = this.sharedService.getTemplatesLocal();
        let templatesTemp;
        this.apiService.getTemplates().subscribe(
          (result: any) => {
            templatesTemp = {
              online: result.data,
              offline: localTemplates
            };
          },
          (error) => {
            templatesTemp = {
              online: [],
              offline: localTemplates
            };
          },
          () => {
            this.templates = templatesTemp.online.concat(templatesTemp.offline);
          });
      });
    }
  }

}
