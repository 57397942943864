import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { TemplateBuilderComponent } from './template-builder.component';

// Consider using this interface for all CanDeactivate guards,
// and have your components implement this interface, too.
//
//   e.g. export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
//
// export interface CanComponentDeactivate {
// canDeactivate: () => any;
// }

@Injectable({providedIn: 'root'})
export class TemplateBuilderGuard implements CanDeactivate<TemplateBuilderComponent> {
  canDeactivate(
    component: TemplateBuilderComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot
  ): Observable<boolean>|Promise<boolean>|boolean {
    if (component.isModified) {
      return confirm('Nem mentett változásaid vannak! Biztos el akarod hagyni az oldalt?');
    }
    return true;
  }
}
