import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateSelectorComponent } from './template-selector.component';
import { TemplateSelectorResolver } from './template-selector.resolver';
import { SharedModule } from '../shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatTooltipModule,
    MatSnackBarModule
  ],
  declarations: [
    TemplateSelectorComponent
  ],
  providers: [
    TemplateSelectorResolver
  ]
})
export class TemplateSelectorModule { }
