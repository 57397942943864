import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { TemplateSelectorModule } from './template-selector/template-selector.module';
import { TemplateBuilderModule } from './template-builder/template-builder.module';
import { CoreModule } from './core/core.module';
import { NgxTinymceModule } from 'ngx-tinymce';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { initEnvironment, EnvironmentInitService } from './environment.service';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'top' }),
    NgxTinymceModule.forRoot({
      baseURL: './assets/tinymce/'
    }),
    SweetAlert2Module.forRoot(),
    CoreModule,
    TemplateSelectorModule,
    TemplateBuilderModule
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    EnvironmentInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initEnvironment,
      deps: [EnvironmentInitService],
      multi: true
    },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
