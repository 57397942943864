import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ITemplateSection, ITemplateSectionImage, SideBarType, ITemplateSectionVideo } from 'src/app/shared/shared.definitions';
import { SharedService } from 'src/app/shared/shared.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/core/api.service';

declare var Dropzone: any;

@Component({
  selector: 'app-video-editor',
  templateUrl: './video-editor.component.html',
  styleUrls: ['./video-editor.component.scss']
})
export class VideoEditorComponent implements OnInit, AfterViewInit {

  @Input() sidenav: MatSidenav;

  selectedSection: ITemplateSection;
  content: ITemplateSectionVideo;
  videoForm: FormGroup;
  isModified: boolean;
  dropzone: any;

  constructor(
    private sharedService: SharedService,
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    Dropzone.options.dropzone = {
      autoProcessQueue: false,
      maxFiles: 1,
      accept: (file, done) => {
        this.videoForm.controls.file.setValue(file);
        done();
      },
      acceptedFiles: 'image/*',
      maxfilesexceeded: function(file) {
        this.removeAllFiles();
        this.addFile(file);
      }
    };

    this.sharedService.selectedSection.subscribe(section => {
      this.selectedSection = section;
      this.content = <ITemplateSectionVideo>(this.selectedSection.content || {});
      this.videoForm = new FormGroup({
        src: new FormControl(this.content.src || ''),
        width: new FormControl(this.content.width || 'auto'),
        height: new FormControl(this.content.height || 'auto'),
        imageSrc: new FormControl(this.content.imageSrc || ''),
        file: new FormControl(),
        titleOptions: new FormGroup({
          title: new FormControl(this.content.titleOptions && this.content.titleOptions.title || ''),
          color: new FormControl(this.content.titleOptions && this.content.titleOptions.color || '#333'),
          size: new FormControl(this.content.titleOptions && this.content.titleOptions.size || '16px'),
          font: new FormControl( 'Noto Sans'),
        }),
        options: new FormGroup({
          verticalAlign: new FormControl(this.content.options && this.content.options.verticalAlign || 'middle'),
          horizontalAlign: new FormControl(this.content.options && this.content.options.horizontalAlign || 'center'),
          paddingTop: new FormControl(this.content.options && this.content.options.paddingTop || '5px'),
          paddingBottom: new FormControl(this.content.options && this.content.options.paddingBottom || '5px'),
          paddingLeft: new FormControl(this.content.options && this.content.options.paddingLeft || '5px'),
          paddingRight: new FormControl(this.content.options && this.content.options.paddingRight || '5px'),
          marginTop: new FormControl(this.content.options && this.content.options.marginTop || '5px'),
          marginBottom: new FormControl(this.content.options && this.content.options.marginBottom || '5px'),
          marginLeft: new FormControl(this.content.options && this.content.options.marginLeft || '5px'),
          marginRight: new FormControl(this.content.options && this.content.options.marginRight || '5px'),
          backgroundColor: new FormControl(this.content.options && this.content.options.backgroundColor || '#ffffff'),
        })
      });

      this.videoForm.valueChanges.subscribe(() => this.isModified = true);
    });
  }

  ngAfterViewInit() {
    this.dropzone = new Dropzone('.dropzone');
  }

  onSave() {
    const { file } = this.videoForm.value;
    Object.keys(this.videoForm.value).forEach(key => {
      if (key !== 'file') {
        this.content[key] = this.videoForm.value[key];
      }
    });
    if (file) {
      this.apiService.uploadImage('file', file).subscribe(
      (res) => {
        try {
          this.content.imageSrc = res.data[0] && res.data[0].content;
          this.saveContent();
        } catch {
          this.snackBar.open('Kép mentése sikertelen!', '',
          { duration: 5000, panelClass: 'error', verticalPosition: 'top' });
        }
      },
      (err) => {
        // this.content.imageSrc = URL.createObjectURL(file);
        this.snackBar.open('Kép mentése sikertelen! A maximális képméret: 5MB', '',
          { duration: 5000, panelClass: 'error', verticalPosition: 'top' });
      });
    } else {
      this.saveContent();
    }
  }

  private saveContent() {
    this.selectedSection.content = this.content;
    this.isModified = false;
    this.sharedService.editSection(this.selectedSection);
    this.sidenav.close();
  }

  onDelete() {
    this.sharedService.deleteSection(this.selectedSection);
    this.sidenav.close();
  }

  onBack() {
    let confirmation = true;
    if (this.isModified) {
      confirmation = confirm('Nem mentett változásaid vannak! Biztos visszalépsz?');
    }
    if (confirmation) {
      this.isModified = false;
      this.sidenav.close();
    }
  }

}
