import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { ITemplate, ITemplateDetails, ITemplateSectionTwinArticle, SideBarType } from 'src/app/shared/shared.definitions';
import { SharedService } from 'src/app/shared/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-template-settings',
  templateUrl: './template-settings.component.html',
  styleUrls: ['./template-settings.component.scss']
})
export class TemplateSettingsComponent implements OnInit {

  @Input() sidenav: MatSidenav;

  templateSettingsForm: FormGroup;
  template: ITemplate;
  matcher: ErrorStateMatcher;
  content: ITemplateSectionTwinArticle;
  tinymceConfig: any;

  constructor(
    private sharedService: SharedService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.sharedService.template.subscribe(template => {
      this.template = template;
      this.templateSettingsForm = new FormGroup({
        // preHeader: new FormControl(this.template.details && this.template.details.preHeader || ''),
        unsubscribe: new FormControl(this.template.details && this.template.details.unsubscribe || ''),
        paddingTop: new FormControl(this.template.details && this.template.details.paddingTop || '10px'),
        paddingBottom: new FormControl(this.template.details && this.template.details.paddingBottom || '10px'),
        paddingLeft: new FormControl(this.template.details && this.template.details.paddingLeft || '10px'),
        paddingRight: new FormControl(this.template.details && this.template.details.paddingRight || '10px'),
        marginTop: new FormControl(this.template.details && this.template.details.marginTop || '0px'),
        marginBottom: new FormControl(this.template.details && this.template.details.marginBottom || '0px'),
        backgroundColor: new FormControl(this.template.details && this.template.details.backgroundColor || '#F2F2F2'),
        foregroundColor: new FormControl(this.template.details && this.template.details.foregroundColor || '#fff'),
        maxWidth: new FormControl(this.template.details && this.template.details.maxWidth || '600px'),
        templateHash: new FormControl('', this.templateHashValidator),
        footer: new FormControl(this.template.details && this.template.details.footer || '')
      });
    });

    this.tinymceConfig = {
      plugins: 'textcolor colorpicker image link table code paste',
      toolbar1:  `undo redo | styleselect | fontselect fontsizeselect | bold italic |
        alignleft aligncenter alignright alignjustify | indent outdent | forecolor backcolor`,
      toolbar2: `link image table | code`,
      height: 350,
      inline_styles: true,
      entity_encoding: 'raw',
      paste_auto_cleanup_on_paste: true,
      fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
      font_formats: 'Noto Sans=Noto Sans; Noto Serif=Noto Serif; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
      content_style: '@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap"); body { font-family: Noto Sans; }',
      paste_postprocess: (pl, o) => {
          o.node.innerHTML = o.node.innerHTML.replace(/&nbsp;/ig, ' ');
      },
    };
  }

  onDownloadTemplate() {
    const templateString = btoa(unescape(encodeURIComponent(JSON.stringify(this.template))));
    this.downloadFile(this.template.details.name, templateString);
  }

  onPasteTemplate() {
    const { templateHash } = this.templateSettingsForm.value;
    if (templateHash) {
      const template = JSON.parse(decodeURIComponent(escape(atob(templateHash))));
      this.sharedService.updateTemplate(template, true);
    }
  }

  onSave() {
    const {
      unsubscribe, paddingTop, paddingBottom, paddingLeft, paddingRight,
      marginTop, marginBottom, backgroundColor, maxWidth, foregroundColor, footer
    } = this.templateSettingsForm.value;
    const details: ITemplateDetails = {
      unsubscribe, paddingTop, paddingBottom, paddingLeft, paddingRight,
      marginTop, marginBottom, backgroundColor, maxWidth, foregroundColor, footer
    };
    this.sharedService.setTemplateDetails(details);
    this.sidenav.close();
  }

  onBack() {
    this.sidenav.close();
  }

  private templateHashValidator(control: FormControl): ValidationErrors {
    let error = false;
    try {
      const template = JSON.parse(decodeURIComponent(escape(atob(control.value))));
    } catch (e) {
      error = true;
    }
    return error ? { invalidHash: true } : null;
  }

  private downloadFile(filename: string, text: string) {
    const _filename = (filename || 'template').normalize('NFD').toLowerCase().replace(/\s/gi, '_');
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + text);
    element.setAttribute('download', `${_filename}.txt`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

}
