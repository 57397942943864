import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CoreHttpInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = environment.token;

    req = req.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: (token ? token : ''),
      }
    });

    return next.handle(req);
  }
}
