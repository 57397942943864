import { NgModule } from '@angular/core';
import { TemplateBuilderComponent } from './template-builder.component';
import { SharedModule } from '../shared/shared.module';
import { SectionComponent } from './components/section/section.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutListComponent } from './components/layout-list/layout-list.component';
import { SectionListComponent } from './components/section-list/section-list.component';
import { TextEditorComponent } from './components/editors/text-editor/text-editor.component';
import { ImageEditorComponent } from './components/editors/image-editor/image-editor.component';
import { SocialEditorComponent } from './components/editors/social-editor/social-editor.component';
import { NgxTinymceModule } from 'ngx-tinymce';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SeparatorEditorComponent } from './components/editors/separator-editor/separator-editor.component';
import { TemplateSettingsComponent } from './components/template-settings/template-settings.component';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { TemplateBuilderResolver } from './template-builder.resolver';
import { TemplateBuilderGuard } from './template-builder.guard';
import { ArticleEditorComponent } from './components/editors/article-editor/article-editor.component';
import { VideoEditorComponent } from './components/editors/video-editor/video-editor.component';
import { TwinArticleEditorComponent } from './components/editors/twin-article-editor/twin-article-editor.component';
import { TemplateBuilderActionsRowComponent } from './components/template-builder-header/template-builder-actions-row.component';

@NgModule({
  imports: [
    SharedModule,
    DragDropModule,
    NgxTinymceModule,
    MatTabsModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatSlideToggleModule
  ],
  declarations: [
    TemplateBuilderComponent,
    SectionComponent,
    LayoutListComponent,
    SectionListComponent,
    TextEditorComponent,
    ImageEditorComponent,
    SocialEditorComponent,
    SeparatorEditorComponent,
    TemplateSettingsComponent,
    ArticleEditorComponent,
    VideoEditorComponent,
    TwinArticleEditorComponent,
    TemplateBuilderActionsRowComponent
  ],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher
    },
    TemplateBuilderResolver,
    TemplateBuilderGuard
  ],
  entryComponents: [
    SectionComponent
  ]
})
export class TemplateBuilderModule { }
