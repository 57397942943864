import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SharedService } from './shared.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SafeUrlPipe } from './pipes/safeUrl.pipe';
import { SafeStylePipe } from './pipes/safeStyle.pipe';

@NgModule({
  imports: [
    RouterModule,
    MatIconModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module
  ],
  exports: [
    RouterModule,
    MatIconModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    SafeHtmlPipe,
    SafeUrlPipe,
    SafeStylePipe,
    SweetAlert2Module
  ],
  declarations: [
    SafeHtmlPipe,
    SafeUrlPipe,
    SafeStylePipe
  ],
  providers: [
    SharedService
  ],
})
export class SharedModule { }
