import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { ApiService } from '../core/api.service';

@Injectable()
export class TemplateSelectorResolver implements Resolve<any> {

  constructor(
    private apiService: ApiService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<any> {

    const localTemplates = localStorage.getItem('templates') ? JSON.parse(localStorage.getItem('templates')) : [];

    return Observable.create((observer: Observer<any>) => {
      this.apiService.getTemplates().subscribe(
        (result: any) => {
          observer.next({
            online: result.data,
            offline: localTemplates
          });
          observer.complete();
        },
        (error) => {
          observer.next({
            online: [],
            offline: localTemplates
          });
          observer.complete();
        });
    });
  }
}
