import { Component, OnInit, Input } from '@angular/core';
import { ITemplateSection, ITemplateRow } from 'src/app/shared/shared.definitions';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-layout-list',
  templateUrl: './layout-list.component.html',
  styleUrls: ['./layout-list.component.scss']
})
export class LayoutListComponent implements OnInit {

  @Input() sidenav: MatSidenav;

  sectionRows: ITemplateRow[];

  constructor() { }

  ngOnInit() {
    this.sectionRows = this.getTemplateRows();
  }

  getTemplateRows(): ITemplateRow[] {
    return [
      {
        id: null,
        height: '100px',
        sections: [
          { id: null, icon: null, layout: { size: 100 }, content: null }
        ],
      },
      {
        id: null,
        height: '100px',
        sections: [
          { id: null, icon: null, layout: { size: 20 }, content: null },
          { id: null, icon: null, layout: { size: 80 }, content: null }
        ],
      },
      {
        id: null,
        height: '100px',
        sections: [
          { id: null, icon: null, layout: { size: 40 }, content: null },
          { id: null, icon: null, layout: { size: 60 }, content: null }
        ],
      },
      {
        id: null,
        height: '100px',
        sections: [
          { id: null, icon: null, layout: { size: 50 }, content: null },
          { id: null, icon: null, layout: { size: 50 }, content: null }
        ],
      },
      {
        id: null,
        height: '100px',
        sections:  [
          { id: null, icon: null, layout: { size: 60 }, content: null },
          { id: null, icon: null, layout: { size: 40 }, content: null }
        ],
      },
      {
        id: null,
        height: '100px',
        sections:  [
          { id: null, icon: null, layout: { size: 80 }, content: null },
          { id: null, icon: null, layout: { size: 20 }, content: null }
        ],
      },
      {
        id: null,
        height: '100px',
        sections:  [
          { id: null, icon: null, layout: { size: 33.33 }, content: null },
          { id: null, icon: null, layout: { size: 33.33 }, content: null },
          { id: null, icon: null, layout: { size: 33.33 }, content: null }
        ],
      },
      {
        id: null,
        height: '100px',
        sections: [
          { id: null, icon: null, layout: { size: 20 }, content: null },
          { id: null, icon: null, layout: { size: 20 }, content: null },
          { id: null, icon: null, layout: { size: 60 }, content: null }
        ],
      },
      {
        id: null,
        height: '100px',
        sections: [
          { id: null, icon: null, layout: { size: 60 }, content: null },
          { id: null, icon: null, layout: { size: 20 }, content: null },
          { id: null, icon: null, layout: { size: 20 }, content: null }
        ],
      },
      {
        id: null,
        height: '100px',
        sections: [
          { id: null, icon: null, layout: { size: 25 }, content: null },
          { id: null, icon: null, layout: { size: 25 }, content: null },
          { id: null, icon: null, layout: { size: 25 }, content: null },
          { id: null, icon: null, layout: { size: 25 }, content: null }
        ]
      },
    ];
  }

  onBack() {
    this.sidenav.close();
  }

}
