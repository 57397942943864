import { Injectable } from '@angular/core';

declare var window: any;

@Injectable()
export class EnvironmentInitService {

  constructor() { }

  public initEnvironment(): Promise<any> {
    return new Promise((resolve) => {
      const queryParams: any = this.getQueryParams();
      window.config = queryParams;
      resolve();
    });
  }

  private getQueryParams(): any {
    const queryString: string = window.location.search;
    const queryParams = {};
    if (queryString) {
      const queryArray = (queryString[0] === '?' ? queryString.slice(1) : queryString).split('&');
      queryArray.forEach(param => {
        const [key, value] = param.split('=');
        queryParams[key] = value;
      });
    }
    return queryParams;
  }
}

export function initEnvironment(environmentInitService: EnvironmentInitService) {
  return () => environmentInitService.initEnvironment();
}
