import { Component, OnInit, ViewChild, Output, EventEmitter, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { ITemplateSection, SideBarType, ITemplateSectionArticle, ITemplateSectionTwinArticle } from 'src/app/shared/shared.definitions';
import { FormGroup, FormControl } from '@angular/forms';
import { TinymceComponent } from 'ngx-tinymce';
import { MatSidenav } from '@angular/material/sidenav';
import { ApiService } from 'src/app/core/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

declare var Dropzone: any;

@Component({
  selector: 'app-twin-article-editor',
  templateUrl: './twin-article-editor.component.html',
  styleUrls: ['./twin-article-editor.component.scss']
})
export class TwinArticleEditorComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('tinymce') private tinymce: TinymceComponent;
  @ViewChild('rightTinymce') private rightTinymce: TinymceComponent;

  @Input() sidenav: MatSidenav;

  selectedSection: ITemplateSection;
  content: ITemplateSectionTwinArticle;
  articleForm: FormGroup;
  tinymceConfig: any;
  isModified: boolean;
  dropzone: any;
  subscription: Subscription;

  constructor(
    private sharedService: SharedService,
    private apiService: ApiService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    Dropzone.options.dropzone = {
      autoProcessQueue: false,
      maxFiles: 1,
      accept: (file, done) => {
        this.articleForm.get('imageOptions').get('file').setValue(file);
        done();
      },
      acceptedFiles: 'image/*',
      maxfilesexceeded: function(file) {
        this.removeAllFiles();
        this.addFile(file);
      }
    };

    this.subscription = this.sharedService.selectedSection.subscribe(section => {
      this.selectedSection = section;
      this.content = <ITemplateSectionTwinArticle>(this.selectedSection.content || {});
      this.articleForm = new FormGroup({
        html: new FormControl(this.content.html || ''),
        rightHtml: new FormControl(this.content.rightHtml || ''),
        leftTitleOptions: new FormGroup({
          title: new FormControl(this.content.leftTitleOptions && this.content.leftTitleOptions.title || ''),
          color: new FormControl(this.content.leftTitleOptions && this.content.leftTitleOptions.color || '#333'),
          size: new FormControl(this.content.leftTitleOptions && this.content.leftTitleOptions.size || '30px'),
        }),
        rightTitleOptions: new FormGroup({
          title: new FormControl(this.content.rightTitleOptions && this.content.rightTitleOptions.title || ''),
          color: new FormControl(this.content.rightTitleOptions && this.content.rightTitleOptions.color || '#333'),
          size: new FormControl(this.content.rightTitleOptions && this.content.rightTitleOptions.size || '30px'),
        }),
        leftButtonOptions: new FormGroup({
          text: new FormControl(this.content.leftButtonOptions && this.content.leftButtonOptions.text || 'Tovább a cikkhez'),
          color: new FormControl(this.content.leftButtonOptions && this.content.leftButtonOptions.color || '#297536'),
          borderColor: new FormControl(this.content.leftButtonOptions && this.content.leftButtonOptions.borderColor || '#297536'),
          backgroundColor: new FormControl(this.content.leftButtonOptions && this.content.leftButtonOptions.backgroundColor || ''),
          href: new FormControl(this.content.leftButtonOptions && this.content.leftButtonOptions.href || ''),
          showButton: new FormControl(
            this.content.leftButtonOptions && this.content.leftButtonOptions.showButton !== null ? this.content.leftButtonOptions.showButton : true),
        }),
        rightButtonOptions: new FormGroup({
          text: new FormControl(this.content.rightButtonOptions && this.content.rightButtonOptions.text || 'Tovább a cikkhez'),
          color: new FormControl(this.content.rightButtonOptions && this.content.rightButtonOptions.color || '#297536'),
          borderColor: new FormControl(this.content.rightButtonOptions && this.content.rightButtonOptions.borderColor || '#297536'),
          backgroundColor: new FormControl(this.content.rightButtonOptions && this.content.rightButtonOptions.backgroundColor || ''),
          href: new FormControl(this.content.rightButtonOptions && this.content.rightButtonOptions.href || ''),
          showButton: new FormControl(
            this.content.rightButtonOptions && this.content.rightButtonOptions.showButton !== null ? this.content.rightButtonOptions.showButton : true),
        }),
        imageOptions: new FormGroup({
          src: new FormControl(this.content.imageOptions && this.content.imageOptions.src || ''),
          file: new FormControl(),
          width: new FormControl(this.content.imageOptions && this.content.imageOptions.width || 'auto'),
          height: new FormControl(this.content.imageOptions && this.content.imageOptions.height || 'auto'),
        }),
        options: new FormGroup({
          verticalAlign: new FormControl(this.content.options && this.content.options.verticalAlign || 'top'),
          horizontalAlign: new FormControl(this.content.options && this.content.options.horizontalAlign || 'left'),
          paddingTop: new FormControl(this.content.options && this.content.options.paddingTop || '5px'),
          paddingBottom: new FormControl(this.content.options && this.content.options.paddingBottom || '5px'),
          paddingLeft: new FormControl(this.content.options && this.content.options.paddingLeft || '5px'),
          paddingRight: new FormControl(this.content.options && this.content.options.paddingRight || '5px'),
          marginTop: new FormControl(this.content.options && this.content.options.marginTop || '5px'),
          marginBottom: new FormControl(this.content.options && this.content.options.marginBottom || '5px'),
          marginLeft: new FormControl(this.content.options && this.content.options.marginLeft || '5px'),
          marginRight: new FormControl(this.content.options && this.content.options.marginRight || '5px'),
          backgroundColor: new FormControl(this.content.options && this.content.options.backgroundColor || '#ffffff'),
          outlookSectionHeight: new FormControl(this.content.options && this.content.options.outlookSectionHeight || 0),
        })
      });

      this.setEditorBackgroundColor(this.articleForm.value.options.backgroundColor);
      this.articleForm.valueChanges.subscribe(() => this.isModified = true);
      this.articleForm.controls.options.get('backgroundColor').valueChanges.subscribe((value) => {
        this.setEditorBackgroundColor(value);
      });
    });

    this.tinymceConfig = {
      plugins: 'textcolor colorpicker image link table code paste',
      toolbar1:  `undo redo | styleselect | fontselect fontsizeselect | bold italic |
        alignleft aligncenter alignright alignjustify | indent outdent | forecolor backcolor`,
      toolbar2: `link image table | code`,
      height: 350,
      inline_styles: true,
      entity_encoding: 'raw',
      paste_auto_cleanup_on_paste: true,
      fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
      font_formats: 'Noto Sans=Noto Sans; Noto Serif=Noto Serif; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
      content_style: '@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap"); body { font-family: Noto Sans; }',
      paste_postprocess: (pl, o) => {
          o.node.innerHTML = o.node.innerHTML.replace(/&nbsp;/ig, ' ');
      },
    };

    this.tinymce.ready.subscribe(() => {
      this.setEditorBackgroundColor(this.articleForm.value.options.backgroundColor);
    });
    this.rightTinymce.ready.subscribe(() => {
      this.setRightEditorBackgroundColor(this.articleForm.value.options.backgroundColor);
    });
  }

  ngAfterViewInit() {
    this.dropzone = new Dropzone('.dropzone');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSave() {
    const { file, ...imageOptions } = this.articleForm.value.imageOptions;
    Object.keys(this.articleForm.value).forEach(key => {
      if (key === 'imageOptions') {
        this.content[key] = imageOptions;
      } else {
        this.content[key] = this.articleForm.value[key];
      }
    });
    if (file) {
      this.apiService.uploadImage('file', file).subscribe(
      (res) => {
        try {
          this.content.imageOptions.src = res.data[0] && res.data[0].content;
          this.saveContent();
        } catch {
          this.snackBar.open('Kép mentése sikertelen!', '',
          { duration: 5000, panelClass: 'error', verticalPosition: 'top' });
        }
      },
      (err) => {
        // this.content.src = URL.createObjectURL(file);
        this.snackBar.open('Kép mentése sikertelen! A maximális képméret: 5MB', '',
          { duration: 5000, panelClass: 'error', verticalPosition: 'top' });
      });
    } else {
      this.saveContent();
    }
  }

  private saveContent() {
    this.selectedSection.content = this.content;
    this.isModified = false;
    this.sharedService.editSection(this.selectedSection);
    this.sidenav.close();
  }

  onDelete() {
    this.sharedService.deleteSection(this.selectedSection);
    this.sidenav.close();
  }

  onBack() {
    let confirmation = true;
    if (this.isModified) {
      confirmation = confirm('Nem mentett változásaid vannak! Biztos visszalépsz?');
    }
    if (confirmation) {
      this.isModified = false;
      this.sidenav.close();
    }
  }

  private setEditorBackgroundColor(color: string) {
    if (this.tinymce.instance) {
      this.tinymce.instance.contentAreaContainer.style.backgroundColor = color;
    }
  }
  private setRightEditorBackgroundColor(color: string) {
    if (this.rightTinymce.instance) {
      this.rightTinymce.instance.contentAreaContainer.style.backgroundColor = color;
    }
  }

}
