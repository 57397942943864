/* Template */
export interface ITemplate {
  id: string;
  details: ITemplateDetails;
  rows: ITemplateRow[];
  html: string;
  isOnline: boolean;
}

export interface ITemplateDetails {
  name?: string;
  preHeader?: string;
  unsubscribe?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  backgroundColor?: string;
  foregroundColor?: string;
  maxWidth?: string;
  footer?: string;
}

/* Section */
export interface ITemplateRow {
  id: number;
  height: string;
  sections: ITemplateSection[];
}

export interface ITemplateSection {
  id: number;
  icon: ITemplateSectionIcon;
  layout: ITemplateSectionLayout;
  content: ITemplateSectionText | ITemplateSectionImage | ITemplateSectionSocial |
           ITemplateSectionSeparator | ITemplateSectionArticle | ITemplateSectionVideo | ITemplateSectionTwinArticle;
}

export interface ITemplateSectionIcon {
  icon: string;
  name: string;
  type: SectionType;
}

export interface ITemplateSectionLayout {
  size: number;
}

export interface ITemplateSectionText {
  html: string;
  options: ITemplateSectionContainerOptions;
}

export interface ITemplateSectionArticle {
  html: string;
  titleOptions: ITemplateSectionArticleTitleOptions;
  buttonOptions: ITemplateSectionArticleButtonOptions;
  imageOptions: ITemplateSectionArticleImageOptions;
  options: ITemplateSectionContainerOptions;
}

export interface ITemplateSectionArticleTitleOptions {
  title: string;
  color: string;
  size: string;
  font: string;
}

export interface ITemplateSectionArticleButtonOptions {
  text: string;
  color: string;
  borderColor: string;
  backgroundColor: string;
  href: string;
  showButton: boolean;
}

export interface ITemplateSectionArticleImageOptions {
  src: string;
  file?: File;
  width: string;
  height: string;
}


export interface ITemplateSectionTwinArticle {
  html: string;
  rightHtml: string;
  leftTitleOptions: ITemplateSectionTwinArticleTitleOptions;
  rightTitleOptions: ITemplateSectionTwinArticleTitleOptions;
  leftButtonOptions: ITemplateSectionTwinArticleButtonOptions;
  rightButtonOptions: ITemplateSectionTwinArticleButtonOptions;
  imageOptions: ITemplateSectionTwinArticleImageOptions;
  options: ITemplateSectionContainerOptions;
}

export interface ITemplateSectionTwinArticleTitleOptions {
  title: string;
  color: string;
  size: string;
}

export interface ITemplateSectionTwinArticleButtonOptions {
  text: string;
  color: string;
  borderColor: string;
  backgroundColor: string;
  href: string;
  showButton: boolean;
}

export interface ITemplateSectionTwinArticleImageOptions {
  src: string;
  file?: File;
  width: string;
  height: string;
}

export interface ITemplateSectionContainerOptions {
  verticalAlign: string;
  horizontalAlign: string;
  paddingTop: string;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;
  marginTop: string;
  marginBottom: string;
  marginLeft: string;
  marginRight: string;
  backgroundColor: string;
  outlookSectionHeight: number;
}

export interface ITemplateSectionImage {
  src: string;
  file?: File;
  width: string;
  height: string;
  link: string;
  options: ITemplateSectionContainerOptions;
}

export interface ITemplateSectionSeparator {
  width: string;
  size: string;
  style: string;
  color: string;
  options: ITemplateSectionContainerOptions;
}

export interface ITemplateSectionSocial {
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  linkedin: string;
  flickr: string;
  issuu: string;
  vimeo: string;
  size: string;
  options: ITemplateSectionContainerOptions;
}

export interface ITemplateSectionVideo {
  src: string;
  title: string;
  titleSize: string;
  width: string;
  height: string;
  imageSrc: string;
  titleOptions: ITemplateSectionArticleTitleOptions;
  options: ITemplateSectionContainerOptions;
}

export interface ITemplateSectionContainerPadding {
  top: string;
  bottom: string;
  left: string;
  right: string;
}

export interface ISidebarType {
  sidebar: SideBarType;
  mode: string;
}

/* Enums */
export enum SectionType {
  TEXT,
  IMAGE,
  SOCIAL,
  SEPARATOR,
  ARTICLE,
  VIDEO,
  TWIN_ARTICLE
}

export enum SideBarType {
  NONE,
  LAYOUT_LIST,
  SECTION_LIST,
  TEMPLATE_SETTINGS,
  EDIT_TEXT,
  EDIT_IMAGE,
  EDIT_SOCIAL,
  EDIT_SEPARATOR,
  EDIT_ARTICLE,
  EDIT_VIDEO,
  EDIT_TWIN_ARTICLE
}
